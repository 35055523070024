const Loader = () => (
  <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
    <div className="relative h-20 w-20 mb-6">
      <div className="absolute inset-0 animate-ping rounded-full border-4 border-blue-600 opacity-75"></div>
      <div className="absolute inset-0 rounded-full border-4 border-blue-600"></div>
    </div>
    <span className="text-2xl font-semibold text-gray-800 animate-pulse">Just a moment...</span>
    <p className="mt-2 text-sm text-gray-600">
      We're preparing your dashboard with fresh insights. Grab a coffee while we load the data!
    </p>
  </div>
);

export default Loader;
