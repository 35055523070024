import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { HiOutlineBell } from 'react-icons/hi';
import classNames from 'classnames';

const NotificationPopover = () => (
  <Popover className="relative">
    {({ open }) => (
      <>
        <Popover.Button
          className={classNames(
            open && 'bg-gray-100',
            'group inline-flex items-center rounded-sm p-1.5 text-gray-700 hover:text-opacity-100 focus:outline-none active:bg-gray-100',
          )}
        >
          <HiOutlineBell fontSize={24} />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute right-0 z-10 mt-2.5 transform w-80">
            <div className="bg-white rounded-sm shadow-md ring-1 ring-black ring-opacity-5 px-2 py-2.5">
              <strong className="text-gray-700 font-medium">Notifications</strong>
              <div className="mt-2 py-1 text-sm">This is the notification panel.</div>
            </div>
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>
);

export default NotificationPopover;
