import React, { useState, createContext, useEffect, useMemo } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

const Loader = React.lazy(() => import('./components/Utils/Loader'));
const ErrorMessage = React.lazy(() => import('./components/Utils/ErrorMessage'));

export const ApiContext = createContext();

const getUniqueSitesCount = (data) =>
  data.filter((site, index, self) => self.findIndex((s) => s.name === site.name) === index).length;

const ApiProvider = ({ children }) => {
  const [sitesData, setSitesData] = useState([]);
  const [counts, setCounts] = useState({ sites: 0, devices: 0, sensors: 0 });
  const [selectedSite, setSelectedSite] = useState(null);
  const [graphsView, setGraphsView] = useState(false);
  const [status, setStatus] = useState({ loading: true, error: null });
  const [siteDetails, setSiteDetails] = useState(null);

  useEffect(() => {
    const fetchSitesData = async () => {
      setStatus({ loading: true, error: null });
      try {
        const { data } = await axios.get('https://the-smart-towers-backend.vercel.app/api/v1/site');

        if (data?.length > 0) {
          const { latitude, longitude } = data[0];
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`,
          );
          setSiteDetails(response.data);
        }

        const uniqueSitesCount = getUniqueSitesCount(data);
        setCounts({
          sites: uniqueSitesCount,
          devices: uniqueSitesCount,
          sensors: uniqueSitesCount * 2,
        });
        setSitesData(data);
      } catch (error) {
        console.error('Error fetching site data:', error);
        setStatus({ loading: false, error: 'Failed to load data. Please try again later.' });
      } finally {
        setStatus((prev) => ({ ...prev, loading: false }));
      }
    };

    fetchSitesData();
  }, []);

  const contextValue = useMemo(
    () => ({
      sitesData,
      sitesCount: counts.sites,
      devicesCount: counts.devices,
      sensorsCount: counts.sensors,
      selectedSite,
      setSelectedSite,
      graphsView,
      setGraphsView,
      siteDetails,
    }),
    [sitesData, counts, selectedSite, graphsView, siteDetails],
  );

  if (status.loading) {
    return (
      <div aria-live="polite">
        <React.Suspense fallback={<div>Loading...</div>}>
          <Loader />
        </React.Suspense>
      </div>
    );
  }

  if (status.error) {
    return (
      <div aria-live="assertive">
        <React.Suspense
          fallback={
            <div>
              {' '}
              Reload the webpage to try again. If the issue persists, please contact support.{' '}
            </div>
          }
        >
          <ErrorMessage message={status.error} />
        </React.Suspense>
      </div>
    );
  }

  return <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>;
};

ApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(ApiProvider);
