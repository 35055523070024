import { Suspense, lazy, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Layout from './components/Layout';
import { ApiContext } from './ApiContext';
import Loader from './components/Utils/Loader';

const Login = lazy(() => import('./pages/Login'));
const Home = lazy(() => import('./pages/Home'));
const Sites = lazy(() => import('./pages/Sites'));
const SensorsData = lazy(() => import('./components/SensorsData/SensorsData'));
const SensorDataView = lazy(() => import('./components/SensorsData/TabularView/SensorsDataView'));
const SensorsTable = lazy(() => import('./components/SensorsTable/SensorsTable'));

const App = () => {
  const { sitesData } = useContext(ApiContext);

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Layout />}>
            {!sitesData ? (
              <Loader />
            ) : (
              <>
                <Route index element={<Home />} />
                <Route path="/:siteName/sensors" element={<SensorsTable />} />
                <Route path="/sensor/:sensorId" element={<SensorsData />} />
                <Route path="/sensor/:sensorId/:attributeName" element={<SensorDataView />} />
                <Route path="/sites" element={<Sites />} />
              </>
            )}
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
