import { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

const SidebarLink = memo(({ link }) => {
  const { pathname } = useLocation();

  const isActive = pathname === link.path;
  const linkStyle = classNames(
    isActive ? 'bg-blue-500 text-white' : 'text-neutral-400 hover:bg-neutral-800 hover:text-white',
    'flex items-center gap-2 px-3 py-2 rounded-sm transition-all duration-200 ease-in-out',
  );

  return (
    <Link to={link.path} className={linkStyle}>
      <span className="text-xl">{link.icon}</span>
      {link.label}
    </Link>
  );
});

export default SidebarLink;
