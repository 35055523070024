import { useState } from 'react';
import { MdFiberSmartRecord } from 'react-icons/md';
import { DASHBOARD_SIDEBAR_LINKS } from './index';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import SidebarLink from './SidebarLink';

export default function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      {/* Toggle Button for Mobile */}
      <button
        className={`absolute top-12 left-4 md:hidden p-2 rounded-md ${
          isOpen ? `bg-red-800 hover:bg-red-700` : `bg-neutral-800 hover:bg-neutral-700`
        } text-neutral-200 transition`}
        onClick={toggleSidebar}
        style={{ zIndex: 2000 }}
      >
        {isOpen ? <IoMdCloseCircleOutline /> : <GiHamburgerMenu />}
      </button>

      {/* Sidebar */}
      <div
        className={`fixed inset-0 opacity-90 md:opacity-100 bg-blue-900 transition-transform transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } md:translate-x-0 md:relative md:w-60 h-full p-4 shadow-lg`}
        style={{ zIndex: 1000 }}
      >
        {/* Sidebar Header */}
        <div className="flex items-center gap-3 pb-4 border-b border-neutral-700">
          <MdFiberSmartRecord fontSize={30} color="white" />
          <span className="text-neutral-200 text-2xl font-semibold tracking-wide">Smart Tower</span>
        </div>

        {/* Sidebar Links */}
        <div className="py-6 flex-1 flex flex-col gap-2">
          {DASHBOARD_SIDEBAR_LINKS.map((link) => (
            <SidebarLink key={link.key} link={link} />
          ))}
        </div>
      </div>
    </>
  );
}
