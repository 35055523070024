import { HiOutlineViewGrid, HiOutlineCube } from 'react-icons/hi';

export const DASHBOARD_SIDEBAR_LINKS = [
  {
    key: 'home',
    label: 'Home',
    path: '/',
    icon: <HiOutlineViewGrid />,
  },
  {
    key: 'sites',
    label: 'Sites',
    path: '/sites',
    icon: <HiOutlineCube />,
  },
];
