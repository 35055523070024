import SearchBar from './SearchBar';
import NotificationPopover from './NotificationPopover';
import UserMenu from './UserMenu';

const Header = () => (
  <header className="bg-white h-16 px-4 flex items-center border-b border-gray-200 justify-between">
    <SearchBar />
    <div className="flex items-center gap-2 mr-2">
      <NotificationPopover />
      <UserMenu />
    </div>
  </header>
);

export default Header;
